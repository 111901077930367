<template>
  <div class="feedback" @click="createWaveSurfer()">
    <div class="user">
      <div class="image">
        <img src="../assets/IngoSchimpff.jpg" />
      </div>
    </div>
    <div class="offer">
      <div class="details">
        <div class="track-details">
          <span class="track-title">
            First Aim Cover
          </span>
          <span class="user-name">
            by Isak Johannesson
          </span>
        </div>
        <div class="offer-details">
          <span class="price">
            $300
          </span>
          <span class="status">
            - Expires in 7 days
          </span>
        </div>
      </div>
      <div class="track">
        <div class="player">
          <div class="controls">
            <div @click="wavesurfer.playPause()" class="play">
              <img src="../assets/icons/Track-Play.svg" />
            </div>
          </div>
          <div class="info">
            <span>{{ current }}</span>
          </div>
          <div class="waveform-container">
            <div id="waveform4"></div>
            <!-- <div :id="waveformId"></div> -->
          </div>
          <div class="info">
            <span>{{ duration }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="input">
      <div class="texta">
        <textarea
          placeholder="Click on the track to comment on a specific part"
        ></textarea>
        <div class="buttons">
          <div class="commentime">
            <div class="icon">
              <div class="icon-fill"></div>
            </div>
            <div class="time">
              <span>0:00</span>
            </div>
          </div>
          <button class="send">
            <ArrowRightIcon :accent="false" />
          </button>
        </div>
      </div>
    </div>
    <div class="description">
      <p class="text">
        Get started by posting your first comment.
      </p>
    </div>
  </div>
</template>

<script>
import WaveSurfer from "wavesurfer.js";
import ArrowRightIcon from "@/assets/icons/Arrow-right.vue";
export default {
  data() {
    return {
      id: "",
      wavesurfer: null,
      track:
        "https://archive.org/download/cd_hypnotize_system-of-a-down_1/disc1/02.%20System%20Of%20A%20Down%20-%20Dreaming_sample.mp3",
      currentSeconds: 0,
      durationSeconds: null,
      trackLoaded: false
    };
  },
  async mounted() {
    if (!this.wavesurfer) this.createWaveSurfer();
  },
  name: "TrackFeedback",
  components: {
    ArrowRightIcon
  },
  computed: {
    waveformId() {
      return `waveform${this.id}`;
    },
    current: function() {
      if (this.trackLoaded) {
        return this.calculateDuration(this.currentSeconds);
      }
      return "0:00";
    },
    duration: function() {
      if (this.trackLoaded) {
        return this.calculateDuration(this.durationSeconds);
      }
      return "0:30";
    }
  },
  methods: {
    calculateDuration(seconds) {
      let sec = (seconds % 60).toFixed(0);
      const min = parseInt(seconds / 60);
      if (sec.toString().length == 1) {
        sec = "0" + sec;
      }
      return min + ":" + sec;
    },
    createWaveSurfer() {
      if (this.wavesurfer) return;
      this.id = Date.now();
      this.wavesurfer = WaveSurfer.create({
        // container: `#waveform${this.Id}`,
        container: `#waveform4`,
        waveColor: "#EDEDED",
        progressColor: "#FF035A",
        cursorColor: "#3B3B3B",
        barHeight: 1,
        barMinHeight: 1,
        barWidth: 3,
        barRadius: 3,
        cursorWidth: 1,
        height: 40,
        barGap: 3
      });
      this.wavesurfer.load(this.track);

      this.wavesurfer.on("ready", () => {
        this.trackLoaded = true;
        this.durationSeconds = this.wavesurfer.getDuration();
      });

      this.wavesurfer.on("audioprocess", () => {
        this.currentSeconds = this.wavesurfer.getCurrentTime();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/_mixins.scss";
.feedback {
  display: flex;
  background: $bg;
  margin-bottom: 40px;
  flex-wrap: wrap;
  .user {
    display: flex;
    flex-direction: column;
    align-items: center;
    .image {
      width: 108px;
      height: 108px;
      overflow: hidden;
      border-radius: 50%;
      img {
        object-fit: cover;
      }
    }
  }
  .offer {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-left: 24px;
    .details {
      width: 100%;
      display: flex;
      .track-details {
        display: flex;
        flex-direction: column;
        flex: 1;
        .track-title {
          @include menu-bold();
        }
        .user-name {
          @include body-light(16px, 24px);
          color: $text-additional;
          margin-top: 8px;
        }
      }
      .offer-details {
        @include body-light();
        .price {
          font-weight: 700;
          color: $accent;
        }
      }
    }
    .track {
      width: 100%;
      margin-top: 16px;
      .player {
        display: flex;
        align-items: baseline;
        .controls {
          width: 38px;
          margin-right: 8px;
          .play {
            img {
              width: 38px;
              height: 38px;
            }
          }
        }
        .info {
          width: 24px;
          span {
            font-size: 12px;
            line-height: 14px;
            letter-spacing: -0.165px;
            color: $text-additional;
          }
        }
        .waveform-container {
          flex: 1;
          display: flex;
          flex-wrap: wrap;
          #waveform4 {
            width: 100%;
            margin: 0 16px;
            height: 40px;
            overflow-y: hidden;
          }
          .comments {
            display: flex;
            width: 100%;
            padding: 0 16px;
            margin-top: 4px;
            position: relative;
            .comment {
              display: flex;
              flex-direction: column;
              align-items: center;
              position: absolute;
              .image {
                width: 18px;
                height: 18px;
                overflow: hidden;
                border-radius: 50%;
                img {
                  object-fit: cover;
                }
              }
            }
          }
        }
      }
    }
  }
  .description {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 32px;
    .text {
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      letter-spacing: -0.22px;
      color: $text-additional;
      span {
        color: $accent;
      }
    }
  }
  .input {
    width: 100%;
    height: 180px;
    padding: 16px;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    border-radius: 5px;
    margin-top: 32px;
    .texta {
      height: 148px;
      // border-top: 1px solid #e0e0e0;
      display: flex;
      flex-direction: column;
      textarea {
        height: 109px;
        // padding: 24px 0;
        border: none;
        background: transparent;
        resize: none;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.22px;
        color: $text-additional;
        &:focus {
          outline: none;
        }
      }
      .buttons {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        button {
          &:focus {
            outline: none;
          }
        }
        .commentime {
          background: #ececec;
          border-radius: 5.5px;
          display: flex;
          align-items: center;
          // padding: 6px 8px;
          padding: 2px 8px 2px 8px;
          .icon {
            width: 11px;
            height: 11px;
            background: white;
            border: 1px solid $text;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 6px;
            .icon-fill {
              width: 7px;
              height: 7px;
              border-radius: 50%;
              background: $text;
            }
          }
          .time {
            display: flex;
            align-items: center;
            padding-bottom: 1px;
            span {
              font-size: 16px;
              line-height: 19px;
              letter-spacing: -0.22px;
              color: $text;
            }
          }
        }
        .send {
          width: 38px;
          height: 38px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: none;
          background: $accent;
          border-radius: 50%;
        }
      }
    }
  }
}
</style>
