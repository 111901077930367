<template>
  <div class="container">
    <div class="content">
      <div class="filters">
        <div class="tab-nav">
          <div class="nav active">
            <div class="title">
              <span class="text">Offers</span>
            </div>
            <span class="underline"></span>
          </div>
          <div class="nav">
            <div class="title">
              <span class="text">In Progress</span>
              <!-- <div class="badge">
                <span>1</span>
              </div> -->
            </div>
            <span class="underline"></span>
          </div>
          <div class="nav">
            <div class="title">
              <span class="text">Completed</span>
            </div>
            <span class="underline"></span>
          </div>
        </div>
        <div class="dropdown">
          <CustomSelect
            :options="[
              { value: 'ff', label: 'Sent' },
              { value: 'ff', label: 'Received' }
            ]"
            default="Sent and Received"
          />
        </div>
      </div>
      <div class="feedbacks">
        <TrackFeedback />
        <TrackFeedback3 />
        <TrackFeedback4 />
        <TrackFeedback2 />
      </div>
    </div>
  </div>
</template>

<script>
import TrackFeedback from "@/components/TrackFeedback.vue";
import TrackFeedback2 from "@/components/TrackFeedback2.vue";
import TrackFeedback3 from "@/components/TrackFeedback3.vue";
import TrackFeedback4 from "@/components/TrackFeedback4.vue";
import CustomSelect from "@/components/Select.vue";
export default {
  name: "Feedback",
  components: {
    TrackFeedback,
    TrackFeedback2,
    TrackFeedback3,
    TrackFeedback4,
    CustomSelect
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
@import "@/styles/_mixins.scss";
.container {
  width: 100%;
  height: 100%;
  .content {
    display: flex;
    padding: 60px 40px;
    flex-direction: column;
    width: 100%;
    .filters {
      display: flex;
      align-items: baseline;
      .tab-nav {
        width: 33%;
        margin-left: 33%;
        display: flex;
        justify-content: center;
        .nav {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 0 30px;
          .title {
            display: flex;
            padding: 0 10px;
            .text {
              @include category-medium();
              color: $text-additional;
              white-space: nowrap;
            }
            .badge {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 12px;
              height: 12px;
              background: $accent;
              border-radius: 50%;
              margin-top: -4px;
              margin-left: -2px;
              span {
                color: $white;
                font-size: 10px;
                line-height: 10px;
              }
            }
          }
          .underline {
            margin-top: 8px;
            height: 2px;
          }
          &.active {
            .title {
              .text {
                color: $text;
              }
            }
            .underline {
              margin-top: 8px;
              width: 100%;
              height: 2px;
              background: $accent;
              border-radius: 50px;
            }
          }
        }
      }
      .dropdown {
        width: 33%;
        display: flex;
        justify-content: flex-end;
      }
    }
    .feedbacks {
      margin-top: 32px;
      .feedback {
        display: flex;
        background: $bg;
        padding: 32px;
        margin-bottom: 40px;
        .user {
          display: flex;
          flex-direction: column;
          align-items: center;
          .image {
            width: 108px;
            height: 108px;
            overflow: hidden;
            border-radius: 50%;
            img {
              object-fit: cover;
            }
          }
        }
        .offer {
          flex: 1;
          display: flex;
          flex-direction: column;
          margin-left: 24px;
          .details {
            width: 100%;
            display: flex;
            .track-details {
              display: flex;
              flex-direction: column;
              flex: 1;
              .track-title {
                @include menu-bold();
              }
              .user-name {
                @include body-light(16px, 24px);
                color: $text-additional;
                margin-top: 8px;
              }
            }
            .offer-details {
              @include body-light();
              .price {
                font-weight: 700;
                color: $accent;
              }
            }
          }
          .track {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
